import React, { Component } from "react"
import { Link } from "gatsby"
import netlify from "../../content/images/netlify.png"
import gatsby from "../../content/thumbnails/gatsby.png"
import github from "../../content/images/github.png"

export default class Footer extends Component {
  render() {
    return (
      <footer
        style={{ padding: "2rem 1rem" }}
        className="flex flex-wrap flex-column items-center justify-center"
      >
        <div className="footer-link-list">

        <span>
            <a href="https://www.youtube.com/channel/UCxs_jV7Wq-xCgQ1L7aGT6uQ/videos" target="_blank" rel="noopener" className="footer-link">
              YouTube
            </a>
          </span>

          <span>
            <Link to="/about" className="footer-link">
              About
            </Link>
          </span>
          <span>
            <Link to="/sponsor" className="footer-link">
              Sponsor
            </Link>
          </span>
          <span>
            <a href="mailto:david@techstacker.com">
              <strong>david@techstacker.com</strong>
            </a>
          </span>
        </div>
        <span>© 2022 TechStacker</span>
      </footer>
    )
  }
}
