const config = {
  siteTitle: "Techstacker",
  siteTitleShort: "Techstacker",
  siteTitleAlt: "Techstacker",
  siteLogo: "/logos/logo.png",
  siteUrl: "https://www.techstacker.com",
  repo: "https://github.com/techstacker/techstacker.com",
  pathPrefix: "",
  dateFromFormat: "YYYY-MM-DD",
  dateFormat: "MMMM Do, YYYY",
  siteDescription:
    "Beginner-friendly tutorials about Web Development, Web Design, SEO, HTML, CSS, JavaScript, React",
  siteRss: "/rss.xml",
  googleAnalyticsID: "UA-105913743-1",
  postDefaultCategoryID: "Tech",
  newsletter: "https://techstacker.substack.com",
  newsletterEmbed: "https://techstacker.substack.com/embed",
  userName: "David",
  userEmail: "techstackermedia@gmail.com",
  userTwitter: "techstacker",

  menuLinks: [
    {
      // name: 'About',
      // link: '/about/',
    },
    {
      name: "Topics",
      link: "/topics/",
    },
    /* {
      name: 'Contact',
      link: '/contact/',
    },
    */
  ],

  desktopMenuLinks: [
    {
      name: "HTML",
      link: "/tags/html",
    },
    {
      name: "CSS",
      link: "/tags/css",
    },
    {
      name: "JavaScript",
      link: "/tags/java-script",
    },
    {
      name: "Mac",
      link: "/tags/mac",
    },
    {
      name: "Typography",
      link: "/tags/typography",
    },
    {
      name: "SEO",
      link: "/tags/seo",
    },
    
    {
      name: "All Topics",
      link: "/topics/",
    },
  ],
  themeColor: "#3F80FF", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff",
}

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = ""
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`

module.exports = config
