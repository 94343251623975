import React, { Component } from "react"
import Helmet from "react-helmet"
import ThemeContext from "../context/ThemeContext"
import Navigation from "../components/Navigation"
import DesktopNavigation from "../components/DesktopNavigation"
import Footer from "../components/Footer"
import config from "../../data/SiteConfig"
import favicon from "../images/favicon.png"
import "../styles/main.scss"

export default class MainLayout extends Component {
  static contextType = ThemeContext

  render() {
    const { dark, notFound } = this.context
    const { children } = this.props
    let themeClass = ""

    if (dark && !notFound) {
      themeClass = "dark"
    } else if (notFound) {
      themeClass = "not-found"
    }

    return (
      <>
        <Helmet
          bodyAttributes={{
            class: `theme ${themeClass}`,
          }}
        >
          <meta name="description" content={config.siteDescription} />
          <link rel="shortcut icon" type="image/png" href={favicon} />
        </Helmet>
        <div className="mobile-nav">
          <Navigation menuLinks={config.menuLinks} />
        </div>
        <div className="desktop-nav">
          <DesktopNavigation desktopMenuLinks={config.desktopMenuLinks} />
        </div>

        <main id="main-content">
          <noscript>
            <p
              className="notebox"
              style={{ textAlign: "center", padding: "1rem" }}
            >
              JavaScript is disabled in your browser. To get the best user
              experience on our website you should enable it :-)
            </p>
          </noscript>
          {children}
        </main>
        <Footer />
      </>
    )
  }
}
